const baseUrl = process.env.REACT_APP_API_ENDPOINT + '/clients'

export type ClientType = {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  phoneNumber: string;
}

export async function readClients (token: string): Promise<ClientType[]> {
  return fetch(
    baseUrl,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => res.json())
    .then(data => data as ClientType[])
}