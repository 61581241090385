import FlexBar from '../FlexBar'
import React from 'react'
import MainLayout from '../MainLayout'
import ProjectCreationForm from './ProjectCreationForm'

const ProjectCreationPage = () => {
  return (
    <MainLayout selectedKeys={['2']}>
      <FlexBar justify="center">
        <h1>Create a new project</h1>
      </FlexBar>
      <FlexBar justify="center">
        <ProjectCreationForm/>
      </FlexBar>
    </MainLayout>
  )
}

export default ProjectCreationPage