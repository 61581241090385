import { ProjectDetailsFormFieldType } from './ProjectDetailsPage'

const baseUrl = process.env.REACT_APP_API_ENDPOINT + '/projects'

export type ProjectDetailsType = {
  id: string;
  title: string;
  client: string;
  projectDate: string;
  invoices: string;
  contract: string;
  workflow: string;
  status: string;
  actions: string;
}

export async function createProject (token: string, values: ProjectDetailsFormFieldType): Promise<ProjectDetailsType> {
  return fetch(
    baseUrl,
    {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as ProjectDetailsType)
}

export async function readProject (token: string, id: string): Promise<ProjectDetailsType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => res.json())
    .then(data => data as ProjectDetailsType)
}

export async function readProjects (token: string): Promise<ProjectDetailsType[]> {
  return fetch(
    baseUrl,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => res.json())
    .then(data => data as ProjectDetailsType[])
}

export async function updateProject (token: string, id: string, values: ProjectDetailsFormFieldType): Promise<ProjectDetailsType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as ProjectDetailsType)
}

export async function deleteProject (token: string, id: string): Promise<Response> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
}