import { Button, Form, Input, Popconfirm } from 'antd'
import FlexBar from '../FlexBar'
import React from 'react'
import { ProjectDetailsFormFieldType } from './ProjectDetailsPage'
import { deleteProject, ProjectDetailsType, updateProject } from './projectHandler'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

type Props = {
  project : ProjectDetailsType | undefined
}
const ProjectDetailsForm = ({project} : Props) => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [form] = Form.useForm()

  form.setFieldsValue(project)

  const onFinish = async (values: ProjectDetailsFormFieldType) => {
    if (project) {
      const token = await getAccessTokenSilently()
      await updateProject(token, project.id, values)
    }
  }

  const onDelete = async () => {
    if (project) {
      const token = await getAccessTokenSilently()
      await deleteProject(token, project.id)
      navigate('/projects')
    }
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ width: 1000, maxWidth: 1200 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item<ProjectDetailsFormFieldType>
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please input a title!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item<ProjectDetailsFormFieldType>
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Please input a status!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <FlexBar justify="flex-start">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Popconfirm
            title="Delete the project"
            description="Are you sure to delete this project?"
            onConfirm={() => onDelete()}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </FlexBar>
      </Form.Item>
    </Form>
  )
}

export default ProjectDetailsForm