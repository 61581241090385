import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import ProjectDetailsPage from './projects/ProjectDetailsPage'
import ProjectCreationPage from './projects/ProjectCreationPage'
import ProjectsPage from './projects/ProjectsPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>
  },
  {
    path: '/projects',
    element: <ProjectsPage/>
  },
  {
    path: '/projects/:projectId',
    element: <ProjectDetailsPage/>
  },
  {
    path: '/createProject',
    element: <ProjectCreationPage/>
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Auth0Provider
    domain="dev-bcj81soqw6qu0b6z.eu.auth0.com"
    clientId="tHgWxKjWA4iYN6mPU7vkXwjmQdcgcP1F"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: 'https://sempiternal.digitalmindshift.de/api',
      scope: 'read:current_user update:current_user_metadata'
    }}
  >
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  </Auth0Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
