import LoginButton from './LoginButton'
import { Flex, Layout, theme } from 'antd'
import React from 'react'

const { Header, Content, Footer } = Layout

const LoginForm = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: colorBgContainer }}/>
      <Content>
        <Flex style={{ minHeight: '240px' }} vertical justify="center" align="center">
          <h1>Please login to continue</h1>
          <LoginButton/>
        </Flex>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Sempiternal ©2023 Created by digitalmindshift.de</Footer>
    </Layout>
  )

}

export default LoginForm