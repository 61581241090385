import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoginForm from './login/LoginForm'
import MainLayout from './MainLayout'
import { Flex } from 'antd'

function App() {
    const {isAuthenticated} = useAuth0();
    if (isAuthenticated) {
        return (
          <MainLayout selectedKeys={['1']}>
              <Flex style={{ minHeight: '240px' }} vertical justify="center" align="center">
                  <h2>Coming soon... 👨‍💻</h2>
              </Flex>
          </MainLayout>
        );
    } else {
        return (
            <LoginForm/>
        );
    }
}

export default App;
