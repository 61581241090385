import React, { useEffect, useState } from 'react'
import { Button, Col, Menu, MenuProps, Row } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import FlexBar from '../FlexBar'
import { ProjectDetailsType, readProject } from './projectHandler'
import { Params, useNavigate, useParams } from 'react-router-dom'
import MainLayout from '../MainLayout'
import ProjectDetailsForm from './ProjectDetailsForm'

export type ProjectDetailsFormFieldType = {
  title?: string;
  status?: string;
};

const items: MenuProps['items'] = [
  {
    label: 'Emails',
    key: 'mail',
  },
  {
    label: 'Invoices',
    key: 'invoices',
  },
  {
    label: 'Forms',
    key: 'forms',
  },
  {
    label: 'Appointments',
    key: 'appointments',
  },
  {
    label: 'Workflows',
    key: 'workflows',
  },
  {
    label: 'Tasks',
    key: 'tasks',
  },
  {
    label: 'Notes',
    key: 'notes',
  },
]

const ProjectDetailsPage = () => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [current, setCurrent] = useState('mail')
  const [project, setProject] = useState<ProjectDetailsType | undefined>(undefined)

  const { projectId }: Readonly<Params<string>> = useParams()

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e)
    setCurrent(e.key)
  }

  useEffect(() => {
    const fetchProject = async (projectId: string) => {
      const token = await getAccessTokenSilently()
      const project = await readProject(token, projectId)
      setProject(project)
    }

    if (projectId) {
      fetchProject(projectId)
    }
  }, [getAccessTokenSilently, projectId])

  return (
    <MainLayout selectedKeys={['2']}>
      <FlexBar justify="flex-start">
        <h1>Project Details</h1>
        <Button type="primary" onClick={() => navigate('/projects')}>Back to projects</Button>
      </FlexBar>
      <FlexBar justify="flex-start">
        <Menu style={{ width: 600 }} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}/>
      </FlexBar>
      <Row>
        <Col span={6}>
          <FlexBar justify="flex-start">
            <ProjectDetailsForm project={project}/>
          </FlexBar>
        </Col>
      </Row>
    </MainLayout>
  )
}

export default ProjectDetailsPage