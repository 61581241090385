import { Button, Form, Input, Select } from 'antd'
import FlexBar from '../FlexBar'
import React, { useEffect, useState } from 'react'
import { createProject } from './projectHandler'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { ClientType, readClients } from '../clients/clientsHandler'

type ProjectCreationFormFieldType = {
  title?: string;
  clientId?: string,
  clientData?: {
    firstName: string;
    lastName: string;
  }
  status?: string;
};

const ProjectCreationForm = () => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [clients, setClients] = useState<ClientType[]>([])
  const [newClient, setNewClient] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (values: ProjectCreationFormFieldType) => {
    const token = await getAccessTokenSilently()
    const project = await createProject(token, values)
    navigate('/projects/' + project.id)
  }

  useEffect(() => {
    const fetchClients = async () => {
      const token = await getAccessTokenSilently()
      const clients = await readClients(token)
      setClients(clients)
    }
    fetchClients()
  }, [getAccessTokenSilently])

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ width: 1000, maxWidth: 1200 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item<ProjectCreationFormFieldType>
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please input a title!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item<ProjectCreationFormFieldType>
        label="Client"
        name="clientId"
        rules={[{ required: !newClient, message: 'Please select a client!' }]}
      >
        <Select
          placeholder="Select a client"
          options={clients.map(c => {return { value: c.id, label: c.firstName + ' ' + c.lastName }})}
          onSelect={() => {
            form.setFieldValue(['clientData', 'firstName'], undefined)
            form.setFieldValue(['clientData', 'lastName'], undefined)
            setNewClient(false)
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => {
            form.setFieldValue('clientId', undefined)
            setNewClient(true)
          }}>
          Create New Client
        </Button>
      </Form.Item>

      {newClient ?
        <>
          <Form.Item<ProjectCreationFormFieldType>
            label="First Name of Client"
            name={['clientData', 'firstName']}
            rules={[{ required: newClient, message: 'Please input a first name!' }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item<ProjectCreationFormFieldType>
            label="Last Name of Client"
            name={['clientData', 'lastName']}
            rules={[{ required: newClient, message: 'Please input a last name!' }]}
          >
            <Input/>
          </Form.Item>
        </>
        : <></>
      }

      <Form.Item<ProjectCreationFormFieldType>
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Please input a status!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <FlexBar justify="flex-start">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="primary" onClick={() => navigate('/projects')}>Cancel</Button>
        </FlexBar>
      </Form.Item>
    </Form>
  )
}

export default ProjectCreationForm