import React from 'react'
import { Button } from 'antd'
import FlexBar from '../FlexBar'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../MainLayout'
import ProjectsTable from './ProjectsTable'

const ProjectsPage = () => {
  const navigate = useNavigate()

  return (
    <MainLayout selectedKeys={['2']}>
      <FlexBar justify="flex-start">
        <h1>Projects</h1>
        <Button type="primary" onClick={() => navigate('/createProject')}>New Project</Button>
      </FlexBar>
      <ProjectsTable />
    </MainLayout>
  )
}

export default ProjectsPage