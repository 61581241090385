import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { ProjectDetailsType, readProjects } from './projectHandler'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const columns: ColumnsType<ProjectDetailsType> = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Client',
    dataIndex: ['clientData', 'lastName'],
    key: 'client',
  },
  {
    title: 'ProjectDate',
    dataIndex: 'projectDate',
    key: 'projectDate',
  },
  {
    title: 'Invoices',
    dataIndex: 'invoices',
    key: 'invoices',
  },
  {
    title: 'Contract',
    dataIndex: 'contract',
    key: 'contract',
  },
  {
    title: 'Workflow',
    dataIndex: 'workflow',
    key: 'workflow',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  }
]

const ProjectsTable = () => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [projects, setProjects] = useState<ProjectDetailsType[]>([])

  useEffect(() => {
    const fetchProjects = async () => {
      const token = await getAccessTokenSilently()
      const projects = await readProjects(token)
      setProjects(projects)
    }
    fetchProjects()
  }, [getAccessTokenSilently])

  return (
    <Table
      style={{ padding: '0px 20px 0px 20px' }}
      columns={columns}
      dataSource={projects}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => navigate('/projects/' + record.id)
        }
      }}
    />
  )
}

export default ProjectsTable