import React, { ReactNode, useState } from 'react'
import {
  BranchesOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileOutlined,
  ToolOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Layout, Menu, theme } from 'antd'
import LogoutButton from './LogoutButton'
import FlexBar from './FlexBar'
import { useNavigate } from 'react-router-dom'

const { Header, Content, Footer, Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number];

function getItem (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Dashboard', '1', <DashboardOutlined/>),
  getItem('Projects', '2', <BranchesOutlined/>),
  getItem('Reporting', 'sub1', <DollarOutlined/>, [
    getItem('Project Sources', '3'),
    getItem('Project Breakdown', '4'),
    getItem('Invoices', '5'),
    getItem('Transactions', '6'),
    getItem('Chart of Accounts', '7'),
    getItem('Reports', '8'),
  ]),
  getItem('Calendar', '9', <CalendarOutlined/>),
  getItem('Tasks', '10', <UnorderedListOutlined/>),
  getItem('Utilities', 'sub2', <ToolOutlined/>, [
    getItem('Form List', '11'),
    getItem('Address Book', '12'),
    getItem('Time Tracker', '13'),
  ]),
  getItem('Templates', 'sub3', <FileOutlined/>, [
    getItem('Scheduler', '14'),
    getItem('Forms', '15'),
    getItem('Canned Emails', '16'),
    getItem('Packages', '17'),
    getItem('Workflows', '18'),
    getItem('Payment Plans', '19'),
  ]),
]

type Props = {
  children: ReactNode,
  selectedKeys: string[]
}

const MainLayout = ({ children, selectedKeys } : Props) => {
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigate = useNavigate()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical"/>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items}
              onClick={(info) => {
                if (info.key === '2') {
                  navigate('/projects');
                } else {
                  navigate('/');
                }
              }} selectedKeys={selectedKeys}/>
      </Sider>
      <Layout>
        <Header style={{ background: colorBgContainer }}>
          <FlexBar justify="flex-end">
            <LogoutButton/>
          </FlexBar>
        </Header>
        <Content>
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>Sempiternal ©2023 Created by digitalmindshift.de</Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout