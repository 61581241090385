import { Flex } from 'antd'
import { ReactNode } from 'react'

type Props = {
  justify: string,
  children: ReactNode,
}
const FlexBar = ({ justify, children }: Props) => {
  return (
    <Flex style={{ height: '64px', padding: '0px 20px 0px 20px' }} justify={justify} align="center" gap="middle"
          wrap="wrap">
      {children}
    </Flex>
  )
}

export default FlexBar